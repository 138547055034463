jQuery(document).ready(function($){


// $('.mudar-cor').on("click", function(){
// 	$('.titulo').css('color', 'grey');
// });




});