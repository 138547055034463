jQuery(document).ready(function($){


/**
 * Esconder e mostrar sidebar
 */
$('.tog').on('click', function(){

	margin_left = $(this).parent().css('margin-left');

	console.log(margin_left);

	if (margin_left === '-175px') {
		$(this).parent().css('margin-left', '0px');
	}
	else if(margin_left === '0px'){
		$(this).parent().css('margin-left', '-175px');
	}
 

});
/**
 * Fim Esconder e mostrar sidebar
 */

/**
 * checkbox activo
 */
$(document).on('change', '#checkbox-activo', function(){

	if($(this).is(":checked")){
		$('#status-activo').val(1);
	}
	else{
		$('#status-activo').val(0);
	}

});
/**
 * Fim checkbox activo
 */

/**
 * checkbox principal
 */
$(document).on('change', '#checkbox-principal', function(){

	if($(this).is(":checked")){
		$('#status-principal').val(1);
	}
	else{
		$('#status-principal').val(0);
	}

});
/**
 * Fim checkbox principal
 */

/**
 * checkbox cal
 */
$(document).on('change', '#checkbox-cal', function(){

	if($(this).is(":checked")){
		$('#status-cal').val(1);
	}
	else{
		$('#status-cal').val(0);
	}

});
/**
 * Fim checkbox cal
 */

/**
 * checkbox meses calendário
 */
$(document).on('change', '.cal-mes-checkbox', function(){

	if($(this).is(":checked")){
		$(this).parent().find('.cal-mes-value').val(1);
	}
	else{
		$(this).parent().find('.cal-mes-value').val(0);
	}

});
/**
 * Fim checkbox meses calendário
 */

/**
 * Adicionar novo slide
 */
$(document).on('click', '#novo-slide', function(){

	$('.apagar-slide').each(function(){
		$(this).removeClass('hidden');
	});

	let last_id = $('.slide-bloco').last().data("id");

	let novo_id = last_id + 1;

	$('.slides').append(`
					<div class="slide-bloco" data-id="${novo_id}">

								<div class="hidden form-group mb-3">
							    	
					    			<input name="slide_id" type="text" class="form-control" id="slide_id" readonly>
					    		</div>

								<div class="input-group mt-5 mb-3">
								    <span class="input-group-text">Titulo Slide</span>
								    <input type="text" class="form-control" aria-label="Titulo" name="titulo_slide[]">
								</div>
									
								<div class="input-group mb-3">
								    <label id="" class="file-label input-group-text" for="">Slide</label>
								    <label id="" class="file-label-input form-control" for="file-input${novo_id}">Upload</label>
								    <input type="file" class="file-input form-control hidden" name="foto[]" id="file-input${novo_id}">
								</div>

								<div class="input-group mb-3">
								    <span class="input-group-text">Texto Slide</span>
								    <textarea class="form-control" aria-label="With textarea" name="texto_slide[]"></textarea>
								</div>


								<div class="d-grid d-md-block">
							  		<button class="apagar-slide btn btn-danger shadow-none" type="button">Apagar Slide</button>
								</div>
							</div>

		`);
 

});
/**
 * Fim Adicionar novo slide
 */

/**
 * Apagar slide
 */

$(document).on('click', '.apagar-slide', function(){

	let id_slide_apagar = $(this).parent().parent().find('.id-slide').val();
	let unlink_ficheiro = $(this).parent().parent().find('.nome-ficheiro-antigo').val();

	if(id_slide_apagar){
		$('.slides-para-apagar').append(`
					<input type="text" name="id_apagar[]" value="${id_slide_apagar}">
					<input type="text" name="unlink_ficheiro[]" value="${unlink_ficheiro}">
			`);
	}

	$(this).parent().parent().remove();
	let num_slides = $('.slide-bloco').length;

	if (num_slides == 1) {
		$('.apagar-slide').addClass('hidden');
	}


});

/**
 * Fim Apagar slide
 */

/**
 * Apagar produto
 */

$(document).on('click', '.apagar-produto', function(){

	let id_produto_apagar = $(this).parent().parent().find('.id-mercado-linha').val();

	if(id_produto_apagar){
		$('.produtos-para-apagar').append(`
					<input type="text" name="id_apagar[]" value="${id_produto_apagar}">
			`);
	}

	$(this).parent().parent().parent().remove();
	let num_produtos = $('.produtos-bloco').length;

	if (num_produtos == 1) {
		$('.apagar-produto').addClass('hidden');
	}


});

/**
 * Fim Apagar produto
 */

/**
 * Buscar idioma
 */
$(document).on('change', '#lg-select', function(){

	let lg_code = $(this).val();
	let ficheiro = 'uploads/flag/' + $('#lg-select option:selected').data('ficheiro');

	$('#lg-code').val(lg_code);
	$('#lg-img').attr('src', ficheiro);
});


/**
 * Fim Buscar idioma
 */

/**
 * Buscar idioma em mercado_novo.php
 */
$(document).on('change', '#lg-select-mercado', function(){

	let lg_code = $(this).val();
	let ficheiro = 'uploads/flag/' + $('#lg-select-mercado option:selected').data('ficheiro');

	$('#lg-code-mercado').val(lg_code);
	$('#lg-img-mercado').attr('src', ficheiro);

	$('.produto-id').each(function(){

		$(this).val('');
	});

	let same_lg_code = 0;

	buscarProdutosLgCode(lg_code, same_lg_code);

	$('.produtos-para-apagar').html('');
});


/**
 * Escolher pais (para ter o id)
 */
$(document).on('change', '#select-pais', function(){

	$('#svg-id').val($(this).val());

});



/**
 * Adicionar novo produto
 */
$(document).on('click', '#novo-produto', function(){

	$('.apagar-produto').each(function(){
		$(this).removeClass('hidden');
	});

	// let last_id = $('.slide-bloco').last().data("id");

	// let novo_id = last_id + 1;

	$('.produtos').append(`
				<div class="produtos-bloco mt-5" >

					
					<div class="form-group mb-3">

						<div class="notas-mercado">
							<aside class="produto-original">
								
							</aside>
							<aside class="aviso-repeticao">

							</aside>
						</div>

					    <input class="id-mercado-linha" type="hidden" name="id_mercado_linha[]">

					    <input class="produto-id" type="hidden" class="form-control" readonly>
						
						<div class="input-group mb-3">
							<span class="input-group-text">Produto</span>
						    <select name="id_produto[]" class="produto-select form-select input-control ">
						    	</select>
						</div>

						<div class="d-grid d-md-block">
					  		<button class="apagar-produto btn btn-danger shadow-none" type="button">Apagar Produto</button>
						</div>

					</div>
				
				</div>

		`);
 
	let lg_code = $('#lg-code-mercado').val();
	let same_lg_code = 1;

	buscarProdutosLgCode(lg_code, same_lg_code)
});
/**
 * Fim Adicionar novo produto
 */

function buscarProdutosLgCode(lg_code, same_lg_code){

	$.getJSON({
		type: 'post',
		url: 'inc_admin/ajax_produtos_mercado.php',
		data: {
			lg_code:lg_code
		},
		success: function(response){
			console.log(response);

			$('.produto-select').each(function(){

				if (!same_lg_code) {

					let texto_select = $(this).find('option:selected').text();

					if ($(this).val()) {

						$(this).parent().parent().find('.produto-original').html(`

								<div class="alert alert-primary alert-dismissible fade show" role="alert">
									<span>Produto Origonal: ${texto_select}</span>
									<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>

								</div>

						`);

					}
				}
//console.log($(this).val());
				if(!$(this).val() || !same_lg_code){

					$(this).html(`
						${response.length ? `<option value=''> -- Seleccionar Produto -- </option>` : `<option value=''>Não existem produtos neste idioma</option>`}
						${response.map(item => `<option value='${item.id_produto}'>${item.nome_produto}</option>`).join('')}
					`);
				
				}
			});

			
		}

	});
}


/**
 * Fim Buscar idioma em mercado_novo.php
 */

/**
 * Buscar produto
 */
$(document).on('change', '.produto-select', function(){

	$(this).parent().parent().find('.aviso-repeticao').html('');

	let produto_select_origin = $(this).parent().parent().find('.produto-id').val();
	let produto_select = $(this).val();

	verificarEscolha(produto_select);

	if(!ja_existe){

		$(this).parent().parent().find('.produto-id').val(produto_select);
	}
	else{

		let texto_select = $(this).find('option:selected').text();

		$(this).parent().parent().find('.aviso-repeticao').html(`

				<div class="alert alert-danger alert-dismissible fade show" role="alert">
					<span><em>${texto_select}</em> já foi seleccionado</span>
				  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>

				</div>

		`);


		if (produto_select_origin) {

			$(this).parent().find('.produto-select option[value='+produto_select_origin+']').prop('selected', true);
		}
		else{
			$(this).val('');
		}
		

	}

});

function verificarEscolha(produto){

	ja_existe = '';

	$('.produto-id').each(function(){
		if($(this).val() == produto && $(this).val() != ''){
			ja_existe = 1;
			
		}
		return ja_existe;
	});

}


/**
 * Fim Buscar produto
 */


$(document).on('change', '.file-input', function(){

    var nome_ficheiro = $(this).val();
    var delimiter = "\\";
    var start = 2;
    var tokens = nome_ficheiro.split(delimiter).slice(start);
    var result = tokens.join(delimiter);

    $(this).parent().parent().find('.slide-img-div').remove();
    $(this).parent().parent().find('.file-label-input').html(result);

});

$(document).on('change', '.file-input-top', function(){

    var nome_ficheiro = $(this).val();
    var delimiter = "\\";
    var start = 2;
    var tokens = nome_ficheiro.split(delimiter).slice(start);
    var result = tokens.join(delimiter);

    $(this).parent().parent().find('.slide-img-div-top').remove();
    $(this).parent().parent().find('.file-label-input-top').html(result);

});



}); // jQuery